<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    '$route' () {
      window.scrollTo(0,0)
      this.$store.dispatch('setUnvisibleDrawer')
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/main';
 #app {
   background: #FFF6ED;
   min-height: 100vh;
 }
</style>
